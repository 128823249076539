import React from 'react';
import styles from './LandingCard.module.scss';

function LandingCard(props) {
  const {children, label, to, img, title} = props;

  return (
    <a href={to} rel="noopener noreferrer">
      <div className={styles['landing-card']}>
        <div className={styles['landing-card--header']}>
          <div
            className={styles['landing-card--header--img']}
            style={{
              backgroundImage: `url('${img}')`,
            }}
          />
        </div>

        <div className={styles['landing-card--container']}>
          <div className={styles['landing-card--container--label']}>
            {label}
          </div>
          <h5 className={styles['landing-card--container--title']}>{title}</h5>

          {children}
        </div>
      </div>
    </a>
  );
}

export default LandingCard;
