import React from 'react';
import PropTypes from 'prop-types';
import Profile from './Profile';

ProfileCB.propTypes = {
  path: PropTypes.string,
  data: PropTypes.object.isRequired,
};

ProfileCB.defaultProps = {
  path: 'DASHBOARD.CBS.VIEWS.VIEW',
};

function ProfileCB(props) {
  const {data, path, ...rest} = props;

  const {
    certification_bodies_id,
    certification_bodies_acronym,
    certification_bodies_name,
    files,
  } = data || {};

  return (
    <Profile
      path={path}
      title={certification_bodies_name}
      subtitle={certification_bodies_acronym}
      params={{cbId: certification_bodies_id}}
      alt={certification_bodies_name}
      image={files}
      {...rest}
    />
  );
}

export default ProfileCB;
