import React from 'react';
import PropTypes from 'prop-types';
import {Link as ReactLink, NavLink, generatePath} from 'react-router-dom';
import {get} from 'lodash/object';
import {isObject} from 'lodash/lang';
import cleanDeep from 'clean-deep';
import urlJoin from 'url-join';
import {ROUTE_PATH} from 'constants/routes';
import {queryStringify} from 'utils/routes';

InternalLink.propTypes = {
  navLink: PropTypes.bool,
  path: PropTypes.string.isRequired,
  search: PropTypes.object,
};

InternalLink.defaultProps = {
  navLink: false,
};

function InternalLink(props) {
  const {
    to, // will be ignored
    navLink,
    path,
    params,
    search,
    ...rest
  } = props;

  let routePath = get(ROUTE_PATH, path, '/');

  if (isObject(routePath) && routePath.hasOwnProperty('INDEX')) {
    routePath = routePath['INDEX'];
  }

  let linkTo = generatePath(routePath, cleanDeep(params));

  if (search) {
    linkTo = urlJoin(linkTo, queryStringify(search, {addQueryPrefix: true}));
  }

  return React.createElement(navLink ? NavLink : ReactLink, {
    to: linkTo,
    ...rest,
  });
}

export default InternalLink;
