/*
  Validator.js
  Docs: https://www.npmjs.com/package/validator
*/

import React from 'react';
import {InputGroup, Input, InputGroupAddon, FormFeedback} from 'reactstrap';
import {useState} from 'react';
import {REGEX_FEEDBACK, REGEX_PATTERN} from 'constants/regex';
import isURL from 'validator/lib/isURL';

const protocols = {
  'https:': 'https://',
  'http:': 'http://',
};

function InputURL(props) {
  const {defaultValue, placeholder, required, name, disabled} = props;
  let defaultSite, defaultProtocol, defaultURL;

  if (
    defaultValue &&
    isURL(defaultValue, {require_valid_protocol: true, require_protocol: true})
  ) {
    defaultSite = new URL(defaultValue);
    defaultProtocol = defaultSite.protocol;
    defaultURL = defaultValue.replace(protocols[defaultProtocol], '');
  } else {
    defaultURL = defaultValue;
  }

  const [url, setUrl] = useState(defaultURL || '');
  const [protocol, setProtocol] = useState(
    (defaultProtocol && protocols[defaultProtocol]) || protocols['https:']
  );

  return (
    <div>
      <input
        type="hidden"
        value={!!url ? protocol.concat(url) : ''}
        name={name}
      />

      <InputGroup>
        <InputGroupAddon addonType="prepend">
          <select
            className="custom-select"
            onChange={(e) => setProtocol(e.target.value)}
            defaultValue={protocol}
            disabled={disabled}
          >
            {Object.values(protocols).map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
        </InputGroupAddon>
        <Input
          onChange={(e) => setUrl(e.target.value)}
          placeholder={placeholder}
          required={required}
          pattern={REGEX_PATTERN.websiteNoProtocol}
          defaultValue={defaultURL}
          disabled={disabled}
        />

        <FormFeedback>{REGEX_FEEDBACK.websiteNoProtocol}</FormFeedback>
      </InputGroup>
    </div>
  );
}

export default InputURL;
