import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {truncate} from 'lodash/string';

import {Row, Col} from 'reactstrap';
import {InternalLink} from 'components/Links';
import {Avatar} from 'components/Avatars';

Profile.propTypes = {
  tag: PropTypes.any,
};

Profile.defaultProps = {
  tag: 'h5',
  avatar: true,
  limit: 30,
};

function Profile(props) {
  const {
    avatar,
    avatarSize,
    tag,
    title,
    subtitle,
    image,
    alt,
    path,
    params,
    limit,
  } = props;

  const _title = limit ? truncate(title, {length: limit}) : title;
  const _subtitle = limit ? truncate(subtitle, {length: limit}) : subtitle;

  return (
    <Row className={cx('mx-n1 align-items-center position-relative no-wrap')}>
      {avatar && (
        <Col className="px-1 col-auto">
          <Avatar
            size={avatarSize}
            image={image}
            alt={alt}
            className="border"
          />
        </Col>
      )}

      <Col className="px-1 overflow-hidden" style={{textOverflow: 'ellipsis'}}>
        {_title ? (
          path ? (
            <InternalLink
              className={cx('h5 text-hover-link stretched-link')}
              path={path}
              params={params}
              title={_title}
            >
              {_title}
            </InternalLink>
          ) : (
            React.createElement(
              tag,
              {
                className: cx('mb-0'),
                style: {
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                },
                title: _title,
              },
              _title
            )
          )
        ) : (
          ''
        )}

        {subtitle && <div className="text-body small">{_subtitle}</div>}
      </Col>
    </Row>
  );
}

export default Profile;
