import {Icon} from 'components/Icons';
import React from 'react';
import {
  Fade,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';

import cx from 'classnames';
import {isEmpty} from 'lodash';

InputClearable.defaultProps = {
  type: 'text',
};

const defaultClass = 'flex-grow-1';

function InputClearable(props) {
  const {
    id,
    keyValue,
    defaultValue,
    toggle,
    className,
    onClear,
    type,
    hasAppendedIcon,
    disabled,
    appendedIcon,
    ...rest
  } = props;

  const _className = defaultClass.concat(` ${className}`);

  return (
    <InputGroup
      style={{cursor: 'pointer'}}
      className={cx('input-group-merge  text-center', {'bg-white': !disabled})}
    >
      <Input
        id={id}
        type={type}
        key={keyValue}
        onClick={toggle}
        className={_className}
        defaultValue={defaultValue}
        {...rest}
      />
      {hasAppendedIcon && isEmpty(defaultValue) && (
        <InputGroupAddon addonType="append" {...rest}>
          <InputGroupText>
            <Icon type="tio" icon={appendedIcon} />
          </InputGroupText>
        </InputGroupAddon>
      )}

      {!isEmpty(defaultValue) && (
        <Fade in={!isEmpty(defaultValue)}>
          <InputGroupAddon addonType="append">
            <InputGroupText>
              <Icon type="fa" icon="times" color="muted" onClick={onClear} />
            </InputGroupText>
          </InputGroupAddon>
        </Fade>
      )}
    </InputGroup>
  );
}

export default InputClearable;
