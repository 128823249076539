import React, {useState, useEffect} from 'react';
import {VIEWPORT_WIDTHS} from 'configs';
import {useLayout} from 'contexts/LayoutContext';
import {useViewport} from 'contexts/ViewportContext';
import PageContainer from './PageContainer';
import PageHeader from './PageHeader';
import PageSidebarPrivate from './PageSidebarPrivate';
import PageFooter from './PageFooter';
import {useLocation} from 'react-router-dom';
import PageSidebarPublic from './PageSidebarPublic';

Page.propTypes = {};

function Page(props) {
  const {children} = props;

  const {header, footer, aside, asideOpen, search, fluid, navLinks, isPublic} =
    useLayout();
  const [collapseAside, setCollapseAside] = useState(false);

  const {pathname} = useLocation();
  const {size} = useViewport();

  const isDesktop = VIEWPORT_WIDTHS.lg < VIEWPORT_WIDTHS[size];

  // Close aside after selecting nav on mobile
  useEffect(() => {
    if (!isDesktop) {
      setCollapseAside(false);
    }
  }, [pathname]);

  function toggleSidebar() {
    setCollapseAside(!collapseAside);
  }

  // Aside
  const _aside =
    aside &&
    (React.isValidElement(aside) ? (
      aside
    ) : !asideOpen ? (
      <PageSidebarPublic
        asideOpen={asideOpen}
        isDesktop={isDesktop}
        collapsed={collapseAside}
        setCollapseAside={toggleSidebar}
      />
    ) : (
      <PageSidebarPrivate
        asideOpen={asideOpen}
        isDesktop={isDesktop}
        collapsed={collapseAside}
        setCollapseAside={toggleSidebar}
      />
    ));

  // Header
  const _header =
    header &&
    (React.isValidElement(header) ? (
      header
    ) : (
      <PageHeader
        aside={aside}
        asideOpen={asideOpen}
        search={search}
        theme={header}
        fluid={fluid}
        navLinks={navLinks}
        collapsed={collapseAside}
        setCollapseAside={toggleSidebar}
        isDesktop={isDesktop}
        isPublic={isPublic}
      />
    ));

  // Page Footer
  const _footer =
    footer &&
    (React.isValidElement(footer) ? footer : <PageFooter fluid={fluid} />);

  return (
    <PageContainer
      isDesktop={isDesktop}
      aside={aside}
      collapsed={collapseAside}
      asideOpen={asideOpen}
    >
      {_header}

      {_aside}

      <main className="main d-flex flex-column">{children}</main>

      {_footer}

      {/*Overlay for aside on mobile*/}
      <div
        className="navbar-vertical-aside-mobile-overlay"
        onClick={() => setCollapseAside(!collapseAside)}
      />
    </PageContainer>
  );
}

export default Page;
