import {Icon} from 'components/Icons';
import React from 'react';
import styles from './LandingLink.module.scss';
import cx from 'classnames';
import PropTypes from 'prop-types';

LandingLink.propTypes = {
  theme: PropTypes.oneOf(['light', 'primary', 'secondary']),
};

LandingLink.defaultProps = {
  theme: 'primary',
};

const linkTheme = {
  light: 'white',
  primary: 'primary',
  secondary: 'secondary',
};

function LandingLink({text, className, theme, disabledHover, ...rest}) {
  return (
    <div
      className={cx(
        styles[disabledHover ? 'landing-button-disabled' : 'landing-button'],
        className
      )}
      {...rest}
    >
      <span
        className={cx(
          styles['landing-button--text'],
          `text-${linkTheme[theme]}`
        )}
      >
        {text}
      </span>
      <span
        className={cx(
          styles['landing-button--icon']
          // `text-${linkTheme[theme]}`
        )}
      >
        <Icon type="tio" icon="add" size="xl" />
      </span>
    </div>
  );
}

export default LandingLink;
