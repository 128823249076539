import React from 'react';
import {getFormattedNumber} from 'utils/numbers';
import {Form, Input, InputGroup} from 'reactstrap';

function Limit(props) {
  const {total, filters, handleChange, value} = props;

  return (
    <Form inline>
      <div className="d-flex flex-row align-items-center">
        <span className="mr-2">Showing:</span>
        <InputGroup className="input-group-borderless flex-grow-1">
          <Input
            type="select"
            className="custom-select"
            value={value}
            onChange={handleChange}
          >
            {filters.map((item, index) => {
              return (
                <option key={index} value={item}>
                  {item}
                </option>
              );
            })}
          </Input>
        </InputGroup>
        <span className="m-1">of</span>
        <span>{getFormattedNumber(total || 0)}</span>
      </div>
    </Form>
  );
}

export default Limit;
