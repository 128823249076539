import React from 'react';
import {Container} from 'reactstrap';

function PublicContainer(props) {
  const {children} = props;
  return (
    <Container className="py-7">
      <div
        className="bg-primary position-fixed"
        style={{width: '100%', height: '40%', top: 0, left: 0}}
      />

      {children}
    </Container>
  );
}

export default PublicContainer;
