import React, {createRef} from 'react';
import PropTypes from 'prop-types';
import {formValidate, formSerialize} from 'utils/forms';

import {DashboardForm} from 'components/Dashboard';
import Modal from './Modal';

ModalForm.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

ModalForm.defaultProps = {
  isOpen: false,
  isSubmitting: false,
  onSubmit: undefined,
  onCancel: undefined,
  okText: 'Submit',
};

function ModalForm(props) {
  const refForm = createRef();

  const {isSubmitting, onSubmit, children, ...rest} = props;

  function handleOk() {
    const form = refForm.current;

    if (formValidate(form)) {
      const formData = formSerialize(form);
      onSubmit(formData);
    }
  }

  return (
    <Modal isLoading={isSubmitting} onOk={handleOk} {...rest}>
      <DashboardForm innerRef={refForm} onSubmit={onSubmit}>
        {children}
      </DashboardForm>
    </Modal>
  );
}

export default ModalForm;
