import React, {useState} from 'react';
import {InputGroup, Input, InputGroupAddon, InputGroupText} from 'reactstrap';
import {REGEX_PATTERN} from 'constants/regex';
import cx from 'classnames';

import {Icon} from 'components/Icons';

const validation = {
  uppercase: 'One uppercase letter(A-Z)',
  lowercase: 'One lowercase letter(a-z)',
  number: 'One number(0-9)',
  special: 'One special character(!,#,$,%)',
  minimum: 'Minimum of 12 characters and maximum of 24',
};

function InputPasswordValidations(props) {
  const {className, onChange, ...rest} = props;
  const [show, setShow] = useState(false);
  const [states, setStates] = useState({
    uppercase: false,
    lowercase: false,
    number: false,
    special: false,
    minimum: false,
  });

  function handleChange(e) {
    e.preventDefault();

    const target = e.target;
    const {value} = target;

    const valid = [
      changeValidation(/[A-Z]/, 'uppercase', value),
      changeValidation(/[a-z]/, 'lowercase', value),
      changeValidation(/[0-9]/, 'number', value),
      changeValidation(/[*@!#$%&()^~{}\s]/, 'special', value),
      changeValidation(/^.{12,24}$/, 'minimum', value),
    ];

    const isValid = valid.every((v) => v);

    const inputGroup = target.closest('.input-group');

    if (target.checkValidity()) {
      inputGroup.classList.remove('is-invalid');
    } else {
      inputGroup.classList.add('is-invalid');
    }

    if (onChange) onChange(value, isValid);
  }

  function changeValidation(regex, key, value) {
    if (regex.test(value)) {
      setStates((prevState) => {
        return {...prevState, [key]: true};
      });

      return true;
    } else {
      setStates((prevState) => {
        return {...prevState, [key]: false};
      });
      return false;
    }
  }

  function handleShow(e) {
    e.preventDefault();
    setShow(!show);
  }

  return (
    <div>
      <InputGroup className={cx('input-group-merge', className)}>
        <Input
          id="input-password"
          type={show ? 'text' : 'password'}
          onKeyUp={handleChange}
          pattern={REGEX_PATTERN.password}
          {...rest}
        />

        <InputGroupAddon addonType="append">
          <InputGroupText className="btn" role="button" onClick={handleShow}>
            <Icon type="fa" icon={show ? 'eye' : 'eye-slash'} />
          </InputGroupText>
        </InputGroupAddon>
      </InputGroup>

      <div style={{backgroundColor: '#F7FAFD'}} className="mt-3 p-3 rounded">
        {Object.keys(validation).map((key) => {
          return (
            <div className="d-block" key={key}>
              <Icon
                type="fa"
                icon={states[key] ? 'check' : 'times'}
                className={states[key] ? 'text-success' : 'text-danger'}
                size="xs"
              />
              <span className="ml-2">{validation[key]}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default InputPasswordValidations;
