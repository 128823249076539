import React from 'react';
import PropTypes from 'prop-types';
import Profile from './Profile';

ProfileUser.propTypes = {
  path: PropTypes.string,
  data: PropTypes.object.isRequired,
};

ProfileUser.defaultProps = {
  path: 'DASHBOARD.USERS.VIEWS.EDIT',
};

function ProfileUser(props) {
  const {data, path, ...rest} = props;

  const {
    account_id,
    account_first_name,
    account_last_name,
    account_email,
    account_image,
  } = data || {};

  const accountName = [account_first_name, account_last_name]
    .filter((v) => v)
    .join(' ');

  return (
    <Profile
      path={path}
      image={account_image}
      title={accountName}
      subtitle={account_email}
      params={{userId: account_id}}
      alt={accountName}
      {...rest}
    />
  );
}

export default ProfileUser;
