import React, {useEffect, useState} from 'react';
import {Dropdown, DropdownMenu, DropdownToggle} from 'reactstrap';
import InputClearable from './InputClearable';
import {DayPicker} from 'components/DatePickers';
import useQuery from 'hooks/useQuery';
import {first, has} from 'lodash';
import moment from 'moment';

InputDayPicker.defaultProps = {
  placeholder: 'Select Date',
};

export function convertDates(dates, isMonthly, isUnix) {
  if (isMonthly)
    return (dates || []).map((date) =>
      moment(new Date(date)).format('MMM YYYY')
    ); // 3x MMM for shorthand month
  return isUnix
    ? (dates || []).map((date) => moment.unix(date).format('YYYY-MM-DD'))
    : dates;
}

function InputDayPicker(props) {
  const {
    name,
    pickerName,
    placeholder,
    pickerPlaceholder,
    defaultValue,
    isRange,
    onClear,
    onConfirm,
    acceptSameDate,
    maxDate,
    minDate,
    isUnix,
  } = props;

  const {query} = useQuery();

  const [date, setDate] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  function handleToggle() {
    setIsOpen(!isOpen);
  }

  function handleClose(callback) {
    setIsOpen(false);

    if (callback) callback();
  }

  function handleClear() {
    setDate([]);
    onClear();
    handleClose();
  }

  function handleConfirm(selected) {
    setDate(selected);
    if (onConfirm) onConfirm(selected);
  }

  useEffect(() => {
    if (has(query[name], first(pickerName))) {
      return setDate(convertDates(defaultValue, false, isUnix));
    }
    setDate([]);
  }, [query]);

  return (
    <div>
      <Dropdown isOpen={isOpen} toggle={handleToggle} direction="down">
        <DropdownToggle
          tag={InputClearable}
          placeholder={placeholder}
          style={{cursor: 'pointer'}}
          defaultValue={(date || []).join(' - ')}
          onClear={handleClear}
          className="text-truncate"
          appendedIcon="calendar"
          hasAppendedIcon
          readOnly
        />

        <DropdownMenu
          className="py-0"
          style={{width: '350px', zIndex: '1006'}}
          positionFixed
        >
          <DayPicker
            isOpen={isOpen}
            isRange={isRange}
            names={pickerName}
            onToggle={handleClose}
            defaultValue={date || []}
            placeholder={pickerPlaceholder}
            onConfirm={handleConfirm}
            acceptSameDate={acceptSameDate}
            maxDate={maxDate}
            minDate={minDate}
            isUnix={isUnix}
          />
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

export default InputDayPicker;
