import React, {useEffect, useRef, useState} from 'react';
import {Dropdown, DropdownMenu} from 'reactstrap';
import InputClearable from './InputClearable';
import cx from 'classnames';
import {MonthPicker} from 'components/DatePickers';
import useQuery from 'hooks/useQuery';
import moment from 'moment';
import {detect} from 'detect-browser';
import {first, last} from 'lodash';

InputMonthPicker.defaultProps = {
  placeholder: 'Select Date',
};

const browser = detect();
const {name: browserName, os} = browser || {};
const isSafari = browserName === 'safari' && (os === 'Mac OS' || os === 'iOS');

export function convertDates(dates) {
  return (dates || []).map((date) => {
    // for safari issue
    const _date = date.split('-');
    const year = first(_date);
    const month = last(_date);

    return moment(isSafari ? new Date(year, month - 1) : new Date(date)).format(
      'MMM YYYY'
    );
  }); // 3x MMM for shorthand month
}

function InputMonthPicker(props) {
  const {
    onConfirm,
    onClear,
    defaultValue,
    name,
    placeholder,
    pickerName,
    pickerPlaceholder,
    isMonthly,
    disabled,
  } = props;

  const dropdown = useRef();
  const {query} = useQuery();

  const [date, setDate] = useState();
  const [isOpen, setIsOpen] = useState(false);

  function handleToggle() {
    setIsOpen((v) => !v);
  }

  function handleClose(callback) {
    setIsOpen(false);

    if (callback) callback();
  }

  useEffect(() => {
    if (query[name]) {
      setDate(convertDates(defaultValue, isMonthly));
      return;
    }
    setDate([]);
  }, [query]);

  // dropdown backdrop event
  useEffect(() => {
    const {containerRef} = dropdown.current || {};

    function handleMouseDown(e) {
      const childNodes = containerRef.current.childNodes;

      function hasChildNodes(element) {
        const {childNodes} = element || {};
        if (childNodes) return loopNodes(childNodes);
      }

      let _childNodes = [];
      function loopNodes(nodes) {
        nodes.forEach((node) => {
          _childNodes.push(node);
          // if has child nodes, loop through all the nodes
          hasChildNodes(node);
        });
      }

      // loop through all the nodes inside the dropdown container using recursion
      // store the nodes in the _childNodes
      loopNodes(childNodes);

      // use the _childNodes to check if the event trigged happened in one of the nodes
      // if so, dont close dropdown
      // if none of the nodes matched with e.target, close the dropdown
      // meaning the user clicked outside of the dropdown
      const isSame = _childNodes.some((v) => v.isSameNode(e.target));

      if (!isSame) handleClose();
    }

    // if dropdown is initialized, add event listener
    if (dropdown.current) {
      window.addEventListener('mousedown', handleMouseDown);
    }

    // remove the event listener, clean up
    return () => window.removeEventListener('mousedown', handleMouseDown);
  }, [dropdown]);

  function handleClick() {
    if (!disabled) {
      handleToggle();
    }
    return false;
  }

  return (
    <div>
      <Dropdown
        ref={dropdown}
        className={cx({'dropdown show': isOpen})}
        toggle={handleToggle}
        direction="down"
      >
        <InputClearable
          style={{cursor: 'pointer'}}
          keyValue={{}} // query
          defaultValue={(date || []).join(' - ')} // dates
          placeholder={placeholder}
          toggle={handleClick}
          onClear={() => {
            setDate([]);
            onClear(handleClose);
          }} // onClear func
          hasIcon
          disabled={disabled}
          readOnly
        />

        <DropdownMenu
          className={cx('py-0', {'dropdown-menu show': isOpen})}
          style={{zIndex: '1006', width: '350px'}}
          right
        >
          <MonthPicker
            isOpen={isOpen}
            names={pickerName}
            isMonthly={isMonthly}
            onToggle={handleClose}
            defaultValue={date || []}
            placeholder={pickerPlaceholder}
            onConfirm={(selected) => {
              setDate(selected);
              if (onConfirm) onConfirm(selected);
            }}
          />
        </DropdownMenu>
      </Dropdown>
    </div>
  );
}

export default InputMonthPicker;
