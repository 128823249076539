import React from 'react';
import ReactPaginate from 'react-paginate';
import cx from 'classnames';

Pagination.defaultProps = {
  hrefBuilder: () => {},
};

function Pagination(props) {
  const {total, offset, hrefBuilder, handleChange, className} = props;

  if (!total || total < 1) return null;

  return (
    <nav aria-label="Page navigation">
      <ReactPaginate
        pageCount={total || 0}
        forcePage={Number(offset || 0)} //To override selected page with parent prop.
        hrefBuilder={hrefBuilder} // The method is called to generate the href attribute value on tag a of each page element.
        onPageChange={handleChange} // The method to call when a page is clicked. Exposes the current page object as an argument.
        containerClassName={cx('pagination mb-0 overflow-auto', className)}
        // Item
        activeClassName="active"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        // Next Item
        nextLabel={total > 1 ? 'Next' : ''}
        nextClassName="page-item"
        nextLinkClassName="page-link"
        // Prev Item
        previousLabel={total > 1 ? 'Prev' : ''}
        previousClassName="page-item"
        previousLinkClassName="page-link"
        // Break Item
        breakClassName="page-item"
        breakLabel={<span className="page-link">...</span>}
        style={{overflow: 'scroll'}}
      />
    </nav>
  );
}

export default Pagination;
