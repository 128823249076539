import React from 'react';
import PropTypes from 'prop-types';
import {Modal, ModalBody, ModalHeader, Button} from 'reactstrap';
import {Icon} from 'components/Icons';
import QRCode from 'qrcode.react';

ModalQRCode.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
  qr: PropTypes.string.isRequired,
  qrName: PropTypes.string.isRequired,
};

function ModalQRCode({isOpen, toggleOpen, qr, qrName}) {
  function downloadQr() {
    const canvas = document.getElementById('qr-code');
    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');
    let downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = `${qrName}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    toggleOpen();
  }

  return (
    <Modal isOpen={isOpen} toggle={toggleOpen} centered>
      <ModalHeader toggle={toggleOpen}>QR Code</ModalHeader>
      <ModalBody>
        <div className="text-center mb-4">
          <QRCode value={qr} size={256} id="qr-code" />
        </div>

        <div className="text-center">
          <Button
            onClick={downloadQr}
            className="text-center"
            color="soft-white"
          >
            <Icon type="fa" icon="download" append="Download QR Code" />
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default ModalQRCode;
