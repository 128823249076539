import styles from './PageHeaderNavNotification.module.scss';

import React, {useState} from 'react';
import {InternalLink} from 'components/Links';
import isEmpty from 'lodash/isEmpty';
import cx from 'classnames';

import {timestampToFromNow} from 'utils/dates';
import {useNotification} from 'contexts/NotificationContext';

import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
} from 'reactstrap';
import {PageLoading} from 'components/Loadings';
import {Icon} from 'components/Icons';
import useGetNotificationProperties from './useGetNotificationProperties';
import {Avatar} from 'components/Avatars';
import capitalize from 'lodash/capitalize';

function NotificationDropAll({handleMarkAll, handleClose, type}) {
  const [isOpen, setIsOpen] = useState(false);

  function toggleOpen() {
    setIsOpen(!isOpen);
  }

  return (
    <ButtonDropdown isOpen={isOpen} toggle={toggleOpen}>
      <DropdownToggle color="light" size="xs" className="rounded">
        <Icon type="fa" icon="ellipsis-h" />
      </DropdownToggle>
      <DropdownMenu right>
        <DropdownItem onClick={() => handleMarkAll(type)}>
          <Icon type="fa" icon="check-double" append="Mark all as read" />
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem
          tag={InternalLink}
          path="DASHBOARD.SETTINGS.NOTIFICATIONS"
          className="text-capitalize"
          style={{letterSpacing: '0px'}}
          onClick={() => handleClose()}
        >
          <Icon type="fa" icon="cog" append="Open Notification Settings" />
        </DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
  );
}

export function NotificationDropdownButton({
  handleMarkAsRead,
  handleRemoveNotification,
  handleTurnOffNotification,
  status,
  className,
}) {
  const [isDropOpen, setIsDropOpen] = useState(false);

  function toggleOpen() {
    setIsDropOpen(!isDropOpen);
  }
  return (
    <ButtonDropdown
      isOpen={isDropOpen}
      toggle={toggleOpen}
      className={cx('mr-4 position-absolute', className)}
    >
      <DropdownToggle
        color="light"
        className="btn-icon rounded-circle"
        title="Notification"
      >
        <Icon type="fa" icon="ellipsis-h" />
      </DropdownToggle>
      <DropdownMenu className="m-0">
        {status === 'unread' && (
          <>
            <DropdownItem onClick={() => handleMarkAsRead()}>
              <Icon type="fa" icon="check" className="mr-2" /> Mark as Read
            </DropdownItem>
            <DropdownItem divider />
          </>
        )}
        <DropdownItem onClick={() => handleRemoveNotification()}>
          <Icon type="fa" icon="times-circle" className="mr-2" /> Remove this
          notification
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem onClick={() => handleTurnOffNotification()}>
          <Icon type="fa" icon="bell-slash" className="mr-2" /> Turn off
          notification like this
        </DropdownItem>
      </DropdownMenu>
    </ButtonDropdown>
  );
}

function NotificationItem({
  notification,
  handleClick,
  handleMarkAsRead,
  handleRemoveNotification,
  handleTurnOffNotification,
}) {
  const {code, status, created_at, details} = notification;

  const {message, title, path, iconBg, icon, companyLogo, params} =
    useGetNotificationProperties(code, details);

  return (
    <DropdownItem toggle={false} className={styles['navigation-item']}>
      <Row>
        <Col
          sm={2}
          className="d-flex justify-content-center align-items-center"
        >
          {icon ? (
            <Icon
              type="fa"
              icon={icon}
              style={{width: '16px', height: '16px'}}
              className={styles[`navigation_item--${iconBg}`]}
            />
          ) : (
            <div>
              <Avatar
                image={companyLogo}
                alt={details.certification_bodies_name || companyLogo}
              />
            </div>
          )}
        </Col>
        <Col sm={8}>
          <InternalLink
            path={path}
            params={params}
            onClick={() => handleClick()}
          >
            <p
              className={cx(
                {
                  'font-weight-bold text-dark': status === 'unread',
                  'text-muted': status === 'read',
                },
                'mb-1 text-wrap'
              )}
            >
              {title}
            </p>
            {message && (
              <div className="border-left border-left-secondary text-dark p-2">
                {message}
              </div>
            )}

            <p
              className={cx(
                {
                  'font-weight-bold text-secondary': status === 'unread',
                  'text-muted': status === 'read',
                },
                'mb-1'
              )}
            >
              {timestampToFromNow(created_at)}
            </p>
          </InternalLink>
        </Col>
        <Col
          sm={2}
          className="d-flex justify-content-center align-items-center px-0"
        >
          <div className={styles['navigation-item__indicator']}>
            <i
              className={cx('text-nowrap legend-indicator bg-secondary', {
                invisible: status === 'read',
              })}
            />
          </div>
          <NotificationDropdownButton
            status={status}
            handleMarkAsRead={handleMarkAsRead}
            handleRemoveNotification={handleRemoveNotification}
            handleTurnOffNotification={handleTurnOffNotification}
            className={styles['navigation-item__button']}
          />
        </Col>
      </Row>
    </DropdownItem>
  );
}

function PageHeaderNavNotification(props) {
  const {theme, type, label, path} = props;
  const {
    // isOpen,
    isLoading,
    // toggleOpen,
    count,
    data,
    isUpdatesOpen,
    isEnquiriesOpen,
    handleClick,
    handleMarkAsRead,
    handleRemoveNotification,
    handleTurnOffNotification,
    handleMarkAll,
    title,
    setType,
    toggleOpenUpdates,
    toggleOpenEnquiries,
  } = useNotification();

  function toggleUpdates() {
    toggleOpenUpdates(type);
    setType(type);
  }

  function toggleEnquiries() {
    toggleOpenEnquiries(type);
    setType(type);
  }

  let hasNotifUpdate, hasNotifEnquiry;

  if (count) {
    hasNotifUpdate = count.update > 0;
    hasNotifEnquiry = count.enquiry > 0;
  }

  const dropdownText =
    isUpdatesOpen && !hasNotifUpdate ? 'No Notifications' : 'No Enquiries';

  return (
    <ButtonDropdown
      isOpen={type === 'update' ? isUpdatesOpen : isEnquiriesOpen}
      toggle={type === 'update' ? toggleUpdates : toggleEnquiries}
    >
      <DropdownToggle
        color={theme === 'dark' ? 'ghost-light' : 'ghost-primary'}
        className="btn-icon rounded-circle"
        title={label}
      >
        {type === 'update' ? (
          <>
            <Icon type="tio" icon="notifications-on-outlined" />
            {hasNotifUpdate && (
              <span className="btn-status btn-sm-status btn-status-danger" />
            )}
          </>
        ) : (
          <>
            <Icon type="tio" icon="messages-outlined" />
            {hasNotifEnquiry && (
              <span className="btn-status btn-sm-status btn-status-danger" />
            )}
          </>
        )}
      </DropdownToggle>
      <DropdownMenu
        right
        style={{width: '350px', minHeight: isLoading ? '320px' : ''}}
      >
        {isLoading && <PageLoading isLoading={isLoading} />}

        {!isLoading && !isEmpty(data) && (
          <>
            <DropdownItem header className="d-flex align-items-center">
              <div className="flex-grow-1">{title}</div>
              <NotificationDropAll
                handleMarkAll={handleMarkAll}
                handleClose={
                  type === 'update' ? toggleOpenUpdates : toggleOpenEnquiries
                }
                toggle={type === 'update' ? toggleUpdates : toggleEnquiries}
                type={type}
              />
            </DropdownItem>
            <DropdownItem divider />
            <div
              className={cx(
                styles['navigation-container'],
                'navbar-vertical-content'
              )}
              style={{minHeight: '320px'}}
            >
              {data.map((item, index) => {
                const {notification_id, account_notification_item, status} =
                  item;

                return (
                  <NotificationItem
                    key={index}
                    notification={item}
                    handleClick={() => handleClick(status, notification_id)}
                    handleMarkAsRead={() => handleMarkAsRead(index, item)}
                    handleRemoveNotification={() =>
                      handleRemoveNotification(notification_id)
                    }
                    handleTurnOffNotification={() =>
                      handleTurnOffNotification({account_notification_item})
                    }
                  />
                );
              })}
            </div>
            <DropdownItem divider />
            <DropdownItem
              tag={InternalLink}
              path={path}
              className="text-center"
            >
              {`View all ${capitalize(label)}`}
            </DropdownItem>
          </>
        )}

        {!isLoading && isEmpty(data) && (
          <DropdownItem className="text-center">{dropdownText}</DropdownItem>
        )}
      </DropdownMenu>
    </ButtonDropdown>
  );
}

export default PageHeaderNavNotification;
