import React from 'react';
import {Nav, NavItem, Button} from 'reactstrap';
import {InternalLink} from 'components/Links';
import {useMaintenance} from 'contexts/MaintenanceContext';
import {Icon} from 'components/Icons';
import PageHeaderNavPrivateUser from './PageHeaderNavPrivateUser';
import PageHeaderNavNotification from './PageHeaderNavNotification';
import cx from 'classnames';
import {PageHeaderNavItem} from './PageHeaderNavPublic';
import {includes} from 'lodash';
import {
  APP_STAGE,
  OPEN_LANDING,
  SHOW_CB_SEARCH_LINK,
} from 'constants/environment';
import styles from './PageHeaderNavPrivate.module.scss';

const PrivateItem = (props) => {
  const {children, ...rest} = props;

  return <NavItem {...rest}>{children}</NavItem>;
};

function PageHeaderNavPrivate(props) {
  const {theme, toggle, isPublic} = props;
  const {isMaintenance} = useMaintenance();

  const env = ['development', 'staging', 'alpha'];
  const isDev = includes(env, APP_STAGE);
  const isOpenLanding = OPEN_LANDING === '1' || OPEN_LANDING === 1;

  const showContent = isDev || isOpenLanding;
  const showCBSearchLink =
    SHOW_CB_SEARCH_LINK === '1' || SHOW_CB_SEARCH_LINK === 1;

  return (
    <Nav className="align-items-center flex-row" navbar>
      <div
        className={cx(styles['page-header-nav-private'], {
          'd-lg-flex': isPublic,
        })}
      >
        <PageHeaderNavItem>
          <a
            href="https://www.ukas.com"
            rel="noreferrer"
            className="text-primary h-100 d-block"
          >
            UKAS.com
          </a>
        </PageHeaderNavItem>
        {showContent && (
          <PageHeaderNavItem>
            <InternalLink path="CONTACT" className="text-primary h-100 d-block">
              Contact
            </InternalLink>
          </PageHeaderNavItem>
        )}

        {showCBSearchLink && (
          <PageHeaderNavItem>
            <InternalLink
              path="CB_SEARCH"
              className="text-primary h-100 d-block"
            >
              Participating Certification Bodies
            </InternalLink>
          </PageHeaderNavItem>
        )}

        <div className={cx(styles['vertical-line'], 'mx-3')} />
      </div>

      <PrivateItem>
        {!isMaintenance && (
          <div className="d-none d-sm-inline">
            <PageHeaderNavNotification
              theme={theme}
              type="message"
              path="DASHBOARD.ENQUIRIES.OPEN"
              label="Enquiries"
            />
            <PageHeaderNavNotification
              theme={theme}
              type="update"
              path="DASHBOARD.SETTINGS.NOTIFICATIONS"
              label="Notifications"
            />
          </div>
        )}

        <div className={cx('d-sm-inline', {'d-none': !isPublic})}>
          <Button
            color={theme === 'dark' ? 'ghost-light' : 'ghost-primary'}
            className="btn-icon rounded-circle "
            tag={InternalLink}
            path="DASHBOARD.OVERVIEW"
            title="Dashboard"
          >
            <Icon type="tio" icon="menu-vs-outlined" />
          </Button>
        </div>
      </PrivateItem>

      <PrivateItem>
        <PageHeaderNavPrivateUser />
      </PrivateItem>

      <Button
        color="white"
        outline
        onClick={toggle}
        className={cx(styles['page-header-toggle'], {'d-lg-none': isPublic})}
      >
        <Icon type="fa" icon="bars" />
      </Button>
    </Nav>
  );
}

export default PageHeaderNavPrivate;
