import React from 'react';
import {Col, Row, CardBody, Card} from 'reactstrap';
import {LinkLogo} from 'components/Links';

PublicFormContainer.defaultProps = {
  col: {md: 7, lg: 5},
  maxWidth: 380,
  logo: true,
};

function PublicFormContainer(props) {
  const {col, maxWidth, children, logo} = props;

  return (
    <Row className="justify-content-center">
      <Col {...col}>
        {logo && (
          <div className="text-center mb-5">
            <LinkLogo color="light" />
          </div>
        )}

        <Card>
          <CardBody className="py-7 px-3 px-lg-5">
            <div
              style={{
                maxWidth: maxWidth ? maxWidth + 'px' : 'auto',
                margin: '0 auto',
              }}
            >
              {children}
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default PublicFormContainer;
