import React from 'react';
import {Button, Nav} from 'reactstrap';
import {InternalLink} from 'components/Links';
import {
  APP_STAGE,
  OPEN_LANDING,
  SHOW_CB_SEARCH_LINK,
} from 'constants/environment';
import {includes} from 'lodash';
import styles from './PageHeaderNavPublic.module.scss';
import cx from 'classnames';
import {Icon} from 'components/Icons';

// @todo: Hide navs for Beta testing
// in phase 1, 23 Jun 2021

export function PageHeaderNavItem({children}) {
  return (
    <div className={cx(styles['page-header-nav-item'], 'mx-3')}>{children}</div>
  );
}

function PageHeaderNavPublic({toggle}) {
  const env = ['development', 'staging', 'alpha'];
  const isDev = includes(env, APP_STAGE);
  const isOpenLanding = OPEN_LANDING === '1' || OPEN_LANDING === 1;

  const showContent = isDev || isOpenLanding;
  const showCBSearchLink =
    SHOW_CB_SEARCH_LINK === '1' || SHOW_CB_SEARCH_LINK === 1;

  return (
    <>
      {/* <NavbarToggler onClick={toggle} className="bg-primary" />

      <Collapse isOpen={isOpen} navbar>
        <Nav navbar className="ml-auto align-items-center flex-row"> */}
      {/*<HeaderNavItem path="ABOUT_US">About us</HeaderNavItem>*/}

      {/*<HeaderNavItem path="CONTACT">Contact</HeaderNavItem>*/}

      {/*<Divider tag={NavItem} type="vertical" />*/}
      <Nav navbar className="ml-auto d-flex align-items-center flex-row">
        <div className="d-none d-lg-flex align-items-center flex-row">
          <PageHeaderNavItem>
            <a
              href="https://www.ukas.com"
              rel="noreferrer"
              className="text-primary h-100 d-block"
            >
              UKAS.com
            </a>
          </PageHeaderNavItem>
          {showContent && (
            <PageHeaderNavItem>
              <InternalLink
                path="CONTACT"
                className="text-primary h-100 d-block"
              >
                Contact
              </InternalLink>
            </PageHeaderNavItem>
          )}

          {showCBSearchLink && (
            <PageHeaderNavItem>
              <InternalLink
                path="CB_SEARCH"
                className="text-primary h-100 d-block"
              >
                Participating Certification Bodies
              </InternalLink>
            </PageHeaderNavItem>
          )}

          <div className={cx(styles['vertical-line'], 'mx-3')} />
        </div>
        <PageHeaderNavItem>
          <InternalLink path="LOG_IN" className="text-primary d-block ">
            Log in
          </InternalLink>
        </PageHeaderNavItem>

        {showContent && (
          <div className="ml-2 d-none d-sm-inline border">
            <Button tag={InternalLink} path="SIGN_UP" color="primary">
              Sign up
            </Button>
          </div>
        )}

        <Button
          className="d-lg-none d-xs-block"
          color="white"
          outline
          onClick={toggle}
        >
          <Icon type="fa" icon="bars" />
        </Button>
      </Nav>
    </>
  );
}

export default PageHeaderNavPublic;
