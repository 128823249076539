import React from 'react';
import {Image} from 'components/Images';

ImageEmptyData.propTypes = {};

function ImageEmptyData(props) {
  return (
    <Image
      src="https://img.certcheck.ukas.com/assets/images/illustrations/empty.svg"
      alt="Empty"
      {...props}
    />
  );
}

export default ImageEmptyData;
