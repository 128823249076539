import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {IMAGE_WIDTHS, SIZES} from 'configs';
import {keys} from 'lodash/object';
import {fileToImageUrl} from 'utils/images';

Image.propTypes = {
  src: PropTypes.string, // Image URI
  file: PropTypes.string, // Stringify Image Data
  defaultSrc: PropTypes.string, // Default Image URI
  size: PropTypes.oneOf(keys(IMAGE_WIDTHS)),
  block: PropTypes.bool,
};

Image.defaultProps = {
  alt: '',
  size: SIZES.md,
  block: false,
};

function Image(props) {
  const {src, file, size, defaultSrc, block, alt, className, ...rest} = props;

  function getSrc() {
    let url = undefined;

    if (src) {
      url = src;
    } else if (file) {
      url = fileToImageUrl(file, size);
    }

    return url || defaultSrc;
  }

  const _src = getSrc();

  if (!_src) return null;

  return (
    <img
      className={classNames('', {
        'd-block': block,
        [className]: !!className,
      })}
      src={_src}
      alt={alt}
      {...rest}
    />
  );
}

export default Image;
