import React from 'react';
import PropTypes from 'prop-types';

import {NavbarBrand} from 'reactstrap';
import {InternalLink} from 'components/Links';
import {ImageLogo, ImageLogoCrown} from 'components/Images';
import {UKAS_TITLE} from 'constants/environment';

LinkLogo.propTypes = {
  color: PropTypes.oneOf(['light', 'dark']).isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'lg']).isRequired,
  isCrown: PropTypes.bool,
};

LinkLogo.defaultProps = {
  color: 'light',
  size: 'md',
  isCrown: false,
};

function LinkLogo(props) {
  const {color, size, isCrown} = props;

  return (
    <NavbarBrand tag={InternalLink} path="HOME" title={UKAS_TITLE}>
      {isCrown ? (
        <ImageLogoCrown color={color} />
      ) : (
        <ImageLogo size={size} color={color} />
      )}
    </NavbarBrand>
  );
}

export default LinkLogo;
