import {Icon} from 'components/Icons';
import {debounce} from 'lodash';
import React, {useCallback} from 'react';
import {Input, InputGroup, InputGroupAddon, InputGroupText} from 'reactstrap';
import PropTypes from 'prop-types';

InputFilter.propsTypes = {
  name: PropTypes.string.isRequired,
  addonType: PropTypes.oneOf(['prepend', 'append']),
};

InputFilter.defaultProps = {
  name: 'search',
  addonType: 'prepend',
  placeholder: 'Search',
};

function InputFilter(props) {
  const {addonType, name, defaultValue, placeholder, onChange} = props;

  function handleOnChange(e) {
    const value = e.target.value;

    if (onChange) return onChange(value);
  }

  const handleOnChangeDebounce = useCallback(debounce(handleOnChange, 500));

  return (
    <InputGroup className="input-group-merge">
      <InputGroupAddon addonType={addonType}>
        <InputGroupText>
          <Icon type="tio" icon="search" className="text-muted" />
        </InputGroupText>
      </InputGroupAddon>

      <Input
        name={name}
        type="search"
        autoComplete="off"
        aria-label="Search"
        defaultValue={defaultValue}
        onChange={handleOnChangeDebounce}
        placeholder={placeholder}
      />
    </InputGroup>
  );
}

export default InputFilter;
