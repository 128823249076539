import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal as ReactModal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';
import {DashboardButton} from 'components/Dashboard';

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,

  cancelButton: PropTypes.bool,
  cancelText: PropTypes.any,
  onCancel: PropTypes.func,
  cancelButtonProps: PropTypes.object,

  okButton: PropTypes.bool,
  okText: PropTypes.any,
  onOk: PropTypes.func,
  okButtonProps: PropTypes.object,

  toggle: PropTypes.bool,
  centered: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  backdrop: PropTypes.oneOf(['static', true, false]),
};

Modal.defaultProps = {
  isOpen: false,
  isLoading: false,

  cancelButton: true,
  cancelText: 'Cancel',
  onCancel: undefined,
  cancelButtonProps: {
    color: 'white',
  },
  okButton: true,
  okText: 'OK',
  onOk: undefined,
  okButtonProps: {
    color: 'primary',
  },

  toggle: true,
  footer: true,
  centered: true,
  size: 'md',
  backdrop: 'static',
};

function Modal(props) {
  const {
    isOpen,
    isLoading,

    cancelButton,
    cancelText,
    onCancel,
    cancelButtonProps,
    okButton,
    okText,
    onOk,
    okButtonProps,

    toggle,
    size,
    centered,
    backdrop,
  } = props;

  const {header, children, footer} = props;

  function handleToggle() {
    handleCancel();
  }

  function handleCancel(e) {
    if (e) e.preventDefault();

    if (!isLoading) {
      onCancel && onCancel();
    }
  }

  function handleOk(e) {
    e.preventDefault();

    onOk && onOk();
  }

  return (
    <ReactModal
      toggle={handleToggle}
      size={size}
      isOpen={isOpen}
      centered={centered}
      backdrop={backdrop}
    >
      <ModalHeader toggle={toggle ? handleToggle : undefined}>
        {header}
      </ModalHeader>

      <ModalBody>{children}</ModalBody>

      {footer && (
        <ModalFooter>
          {cancelButton && (
            <Button
              disabled={isLoading}
              onClick={handleCancel}
              color="white"
              style={{minWidth: 80}}
              {...cancelButtonProps}
            >
              {cancelText}
            </Button>
          )}

          {okButton && (
            <DashboardButton
              color="primary"
              isLoading={isLoading}
              onClick={handleOk}
              style={{minWidth: 80}}
              {...okButtonProps}
            >
              {okText}
            </DashboardButton>
          )}
        </ModalFooter>
      )}
    </ReactModal>
  );
}

export default Modal;
