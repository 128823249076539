import React from 'react';
import PropTypes from 'prop-types';
import {generatePath} from 'react-router-dom';
import {Link as RouterLink, NavLink} from 'react-router-dom';
import {isObject} from 'lodash/lang';
import cleanDeep from 'clean-deep';
import {queryStringify} from 'utils/http';

const propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  blank: PropTypes.bool,
  button: PropTypes.bool,
  params: PropTypes.object,
};

function Link(props) {
  const {
    navLink,
    className,
    href,
    target,
    blank,
    to,
    params,
    search,
    children,
    stretched, // stretched-link
    button, // true, ant-btn
    type, // button type, atn-btn-{type}
    size, // button size, large or small
    ghost, // button background
    block,
    ...rest
  } = props;

  const _target = blank ? '__blank' : target;
  const _rel = blank ? 'noopener noreferrer' : undefined;

  const commons = {
    className: className,
    target: _target,
    rel: _rel,
    ...rest,
  };

  if (href) {
    return (
      <a href={href} rel={_rel} {...commons}>
        {children}
      </a>
    );
  }

  const _search = isObject(search) ? queryStringify(search) : search;

  return React.createElement(
    navLink ? NavLink : RouterLink,
    {
      to: {
        pathname: params ? generatePath(to, cleanDeep(params)) : to,
        search: _search,
      },
      ...commons,
    },
    children
  );
}

Link.propTypes = propTypes;

export default Link;
