import React from 'react';
import PropTypes from 'prop-types';
import BeatLoader from 'react-spinners/BeatLoader';

TextLoading.propTypes = {
  isLoading: PropTypes.bool.isRequired,
};

TextLoading.defaultProps = {
  isLoading: false,
  size: 4,
  margin: 2,
  color: 'black',
};

function TextLoading(props) {
  const {isLoading, size, margin, color} = props;

  return (
    <BeatLoader loading={isLoading} size={size} margin={margin} color={color} />
  );
}

export default TextLoading;
