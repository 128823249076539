import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {includes} from 'lodash';
import {Icon} from 'components/Icons';

ImageFlag.propTypes = {
  code: PropTypes.string,
  tag: PropTypes.string,
  round: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']), // 12px, 18px, 24px, 32px
};

ImageFlag.defaultProps = {
  tag: 'i',
  round: true,
  size: 'md',
};

const COUNTRY_IDS =
  '["DZ","AO","BJ","BW","BF","BI","CM","CV","CF","TD","CD","DJ","EG","GQ","ER","ET","GA","GM","GH","GN","GW","CI","KE","LS","LR","LY","MG","MW","ML","MR","MU","YT","MA","MZ","NA","NE","NG","CG","RE","RW","SH","ST","SN","SC","SL","SO","ZA","SS","SD","SR","SZ","TG","TN","UG","TZ","EH","YE","ZM","ZW","AI","AG","AR","AW","BS","BB","BQ","BZ","BM","BO","VG","BR","CA","KY","CL","CO","KM","CR","CU","CW","DM","DO","EC","SV","FK","GF","GL","GD","GP","GT","GY","HT","HN","JM","MQ","MX","MS","NI","PA","PY","PE","PR","BL","KN","LC","PM","VC","SX","TT","TC","US","VI","UY","VE","AB","AF","AZ","BD","BT","BN","KH","CN","GE","HK","IN","ID","JP","KZ","LA","MO","MY","MV","MN","MM","NP","KP","MP","PW","PG","PH","SG","KR","LK","TW","TJ","TH","TL","TM","VN","AX","AL","AD","AM","AT","BY","BE","BA","BG","HR","CY","CZ","DK","EE","FO","FI","FR","DE","GI","GR","GG","HU","IS","IE","IM","IT","JE","XK","LV","LI","LT","LU","MT","MD","MC","ME","NL","MK","NO","PL","PT","RO","RU","SM","RS","SK","SI","ES","SE","CH","TR","UA","GB","VA","BH","IR","IQ","IL","KW","JO","KG","LB","OM","PK","PS","QA","SA","SY","AE","UZ","AS","AU","CX","CC","CK","FJ","PF","GU","KI","MH","FM","NC","NZ","NR","NU","NF","WS","SB","TK","TO","TV","VU","WF","AQ","EU","JR","OLY","UN"]';

const SUPPORTED_COUNTRY_FLAGS = JSON.parse(COUNTRY_IDS);

function ImageFlag(props) {
  const {tag, code, size, round, className, style} = props;

  if (!includes(SUPPORTED_COUNTRY_FLAGS, code))
    return (
      <Icon
        type="fa"
        icon="circle"
        className="text-light mr-1 border rounded-circle"
        size="lg"
      />
    );

  const _className = cx(
    'fflag',
    {
      [`fflag-${code}`]: code,
      [`ff-${size}`]: size,
      'ff-round': round,
    },
    className
  );

  return React.createElement(tag, {
    className: _className,
    style: {
      verticalAlign: 'text-bottom',
      ...style,
    },
  });
}

export default ImageFlag;
