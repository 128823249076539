import React, {useState} from 'react';
//import PropTypes from 'prop-types';
import {
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Modal as ReactModal,
  ModalHeader,
  ModalBody,
  Button,
} from 'reactstrap';
import {Icon} from 'components/Icons';
import LandingSearch from 'views/landing/LandingSearch';

PageHeaderSearch.propTypes = {};

function PageHeaderSearch() {
  const [showSearch, setShowSearch] = useState(false);

  function handleToggle() {
    setShowSearch(!showSearch);
  }

  return (
    <form>
      <Button
        color="white"
        onClick={handleToggle}
        className="d-md-none close"
        outline
      >
        <Icon type="tio" icon="search" />
      </Button>

      <InputGroup className="input-group-merge input-group-borderless input-group-hover-light navbar-input-group d-none d-md-flex">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <Icon type="tio" icon="search" />
          </InputGroupText>
        </InputGroupAddon>

        <Input
          type="search"
          placeholder="Search company name or certification"
          onClick={handleToggle}
          onMouseDown={(e) => e.preventDefault()}
        />

        {/*<InputGroupAddon addonType="append">
          <InputGroupText>
            <TIOIcon icon="clear" />
          </InputGroupText>
        </InputGroupAddon>*/}
      </InputGroup>

      <ReactModal
        size="lg"
        toggle={handleToggle}
        isOpen={showSearch}
        style={{maxWidth: '100%', margin: 0}}
      >
        <ModalHeader toggle={handleToggle} />
        <ModalBody>
          <LandingSearch onClose={handleToggle} />
        </ModalBody>
      </ReactModal>
    </form>
  );
}

export default PageHeaderSearch;
