import useCountries from 'hooks/useCountries';
import {truncate, isEmpty} from 'lodash';
import {getFormattedNumber} from 'utils/numbers';

export default function useGetNotificationProperties(code, details) {
  let header, path, iconBg, icon, companyLogo, message, params;
  let senderName;
  const {countries} = useCountries();

  const {enquires_meta} = details || {};
  const {current_office_directory} = enquires_meta || {};

  const {street, city, state, postcode, country, office_type} =
    current_office_directory || {};

  const countryName =
    (
      countries.find((item) => {
        return item.countries_id === country;
      }) || {}
    ).name || '';

  const address = [street, city, state, postcode, countryName]
    .filter((v) => v)
    .join(', ');

  switch (code) {
    default:
    case 'ftp_file_upload_success': {
      const {file_name, is_cert_upload, uploaded_cert_count} = details || {};
      if (isEmpty(details) || !is_cert_upload) {
        header = `${file_name} has been successfully uploaded`;
      } else {
        header = `${file_name} - ${uploaded_cert_count} certfications has been successfully uploaded through FTP`;
      }
      icon = 'check-circle';
      iconBg = 'file-success';
      path = 'DASHBOARD.DATA_MANAGEMENT.ACTIVITY';
      break;
    }
    case 'file_upload_success': {
      const {file_name, is_cert_upload, uploaded_cert_count} = details || {};
      if (isEmpty(details) || !is_cert_upload) {
        header = `${file_name} has been successfully uploaded`;
      } else {
        header = `${file_name} - ${uploaded_cert_count} certfications has been successfully uploaded`;
      }
      icon = 'check-circle';
      iconBg = 'file-success';
      path = 'DASHBOARD.DATA_MANAGEMENT.ACTIVITY';
      break;
    }
    case 'ftp_file_upload_error': {
      const {
        file_name,
        is_cert_upload,
        uploaded_cert_count,
        skipped_cert_count,
        error_count,
      } = details || {};
      if (isEmpty(details) || !is_cert_upload) {
        header = `${file_name || 'File'} failed to upload. Please try again.`;
      } else {
        header = `${file_name} - Failed to upload all certifications through FTP. ${uploaded_cert_count} uploaded; ${skipped_cert_count} skipped with ${error_count} errors`;
      }
      icon = 'exclamation-circle';
      iconBg = 'file-error';
      path = 'DASHBOARD.DATA_MANAGEMENT.ACTIVITY';
      break;
    }
    case 'file_upload_error': {
      const {
        file_name,
        is_cert_upload,
        uploaded_cert_count,
        skipped_cert_count,
        error_count,
      } = details || {};
      if (isEmpty(details) || !is_cert_upload) {
        header = `${file_name || 'File'} failed to upload. Please try again.`;
      } else {
        header = `${file_name} - Failed to upload all certifications. ${uploaded_cert_count} uploaded; ${skipped_cert_count} skipped with ${error_count} errors`;
      }
      icon = 'exclamation-circle';
      iconBg = 'file-error';
      path = 'DASHBOARD.DATA_MANAGEMENT.ACTIVITY';
      break;
    }
    case 'two_fa_enabled':
      icon = 'shield-alt';
      iconBg = 'auth-enable';
      header = 'Your 2-step verification settings have been changed.';
      path = 'DASHBOARD.SETTINGS.SECURITY.OVERVIEW';
      break;
    case 'two_fa_disabled':
      icon = 'shield-alt';
      iconBg = 'auth-disable';
      header = 'Your 2-step verification settings have been changed.';
      path = 'DASHBOARD.SETTINGS.SECURITY.OVERVIEW';
      break;
    case 'update_password':
      icon = 'lock';
      iconBg = 'password';
      header = 'Password has been updated.';
      path = 'DASHBOARD.SETTINGS.SECURITY.OVERVIEW';
      break;
    case 'certificate_update':
      icon = 'certificate';
      iconBg = 'certification';
      header = `Certification has been updated`;
      path = 'DASHBOARD.CERTS.INDEX';
      break;
    case 'watchlist_certificate_update':
      icon = 'certificate';
      iconBg = 'certification-watched';
      header = `Certificate ${details.certification_number} by ${details.company_name} is now ${details.status}.`;
      path = 'DASHBOARD.WATCHLIST.INDEX';
      break;
    case 'company_updated':
      icon = 'clipboard-check';
      iconBg = 'company-details';
      header = 'Company details have been updated.';
      path = 'DASHBOARD.OVERVIEW';
      break;
    case 'enquiry':
      senderName = [details.sender_first_name, details.sender_last_name];
      companyLogo = senderName.join(' ');
      iconBg = 'new-enquiries';
      header = `You have a new enquiry from ${senderName.join(' ')}: ${
        details.subject
      }`;
      message = truncate(details.sender_message);
      path = details.enquiry_id
        ? 'DASHBOARD.ENQUIRIES.VIEWS.VIEW'
        : 'DASHBOARD.ENQUIRIES.OPEN';
      params = details.enquiry_id ? {enquiryId: details.enquiry_id} : null;
      break;
    case 'enquiry_reply':
      senderName = [details.sender_first_name, details.sender_last_name];
      companyLogo = senderName.join(' ');
      iconBg = 'reply-enquiries';
      header = `You have a new reply from ${senderName.join(' ')}: ${
        details.subject
      }`;
      message = truncate(details.sender_message);
      path = details.enquiry_id
        ? 'DASHBOARD.ENQUIRIES.VIEWS.VIEW'
        : 'DASHBOARD.ENQUIRIES.OPEN';
      params = details.enquiry_id ? {enquiryId: details.enquiry_id} : null;
      break;
    case 'enquiry_reopen':
      icon = 'envelope-open';
      iconBg = 'reopen-enquiries';
      header = `Your enquiry to ${details.enquiry_sender} has been re-opened: ${details.enquiry_title}`;
      message = truncate(details.enquiry_message);
      path = details.enquiry_id
        ? 'DASHBOARD.ENQUIRIES.VIEWS.VIEW'
        : 'DASHBOARD.ENQUIRIES.OPEN';
      params = details.enquiry_id ? {enquiryId: details.enquiry_id} : null;
      break;
    case 'enquiry_resolved':
      icon = 'envelope';
      iconBg = 'resolved-enquiries';
      header = `Your enquiry to ${details.enquiry_sender} has been resolved: ${details.enquiry_title}`;
      message = truncate(details.enquiry_message);
      path = details.enquiry_id
        ? 'DASHBOARD.ENQUIRIES.VIEWS.VIEW'
        : 'DASHBOARD.ENQUIRIES.OPEN';
      params = details.enquiry_id ? {enquiryId: details.enquiry_id} : null;
      break;
    case 'cb_resigned':
      companyLogo = JSON.parse(details.certification_bodies_logo);
      iconBg = details.certification_bodies_logo;
      header = `Certification body ${details.certification_bodies_name} has resigned their accreditation.`;
      path = 'DASHBOARD.CBS.INDEX';
      break;
    case 'update_office_directory_approved':
      icon = 'check-circle';
      iconBg = 'file-success';
      header = `Your office directory update has been approved.`;
      message = 'Update Request - Approved';
      params = {enquiryId: details.enquiry_id};
      path = 'DASHBOARD.ENQUIRIES.VIEWS.INDEX';
      break;
    case 'update_office_directory_rejected':
      icon = 'times-circle';
      iconBg = 'file-error';
      header = `Your office directory update has been rejected.`;
      message = 'Update Request - Rejected';
      params = {enquiryId: details.enquiry_id};
      path = 'DASHBOARD.ENQUIRIES.VIEWS.INDEX';
      break;

    case 'delete_office_directory_rejected':
      icon = 'times-circle';
      iconBg = 'file-error';
      header = (
        <span className="text-dark">
          Your request to delete the office directory has been rejected.
        </span>
      );
      message = (
        <>
          <p className="text-wrap">
            <span className="text-dark">{office_type}</span>
            <br />
            <span className="text-muted">{address}</span>
          </p>
        </>
      );
      params = {enquiryId: details.enquiry_id};
      path = 'DASHBOARD.ENQUIRIES.VIEWS.INDEX';
      break;

    case 'delete_office_directory_approved':
      icon = 'check-circle';
      iconBg = 'file-success';
      header = (
        <span className="text-dark">
          Your request to delete the office directory has been approved.
        </span>
      );
      message = (
        <>
          <p className="text-wrap">
            <span className="text-dark">{office_type}</span>
            <br />
            <span className="text-muted">{address}</span>
          </p>
        </>
      );
      params = {enquiryId: details.enquiry_id};
      path = 'DASHBOARD.ENQUIRIES.VIEWS.INDEX';
      break;

    case 'cb_iaf_sync_completed':
      const {synced_cert_count} = details || {};
      icon = 'sync';
      iconBg = 'file-success';
      header = `IAF data sync has been completed.`;
      message = `Certifications Updated: ${getFormattedNumber(
        synced_cert_count
      )}`;
      path = 'DASHBOARD.SETTINGS.DATA';
      break;

    case 'cb_iaf_sync_failed':
      icon = 'sync';
      iconBg = 'file-error';
      header = `IAF Data Sync has failed. Please try again.`;
      path = 'DASHBOARD.SETTINGS.DATA';
      break;
  }
  return {
    title: header,
    message: message,
    path: path,
    iconBg: iconBg,
    icon: icon,
    companyLogo: companyLogo,
    params: params,
  };
}
