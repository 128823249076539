import {useState} from 'react';
import useAlert from 'hooks/useAlert';
import useFetch from 'hooks/useFetch';
import {REGEX_FEEDBACK} from 'constants/regex';

function useInputValidate(action, name, required) {
  const [valid, setValid] = useState(null);
  const [errors, setErrors] = useState(
    required ? [REGEX_FEEDBACK.required] : []
  );

  const {alertByError} = useAlert();
  const [fetch, isValidating] = useFetch(action);

  async function validate(inputValue) {
    if (inputValue) {
      try {
        const {data} = await fetch({[name]: inputValue});
        const {valid, errors} = data || {};

        setValid(valid);
        setErrors(errors);
      } catch (e) {
        await alertByError(e);
      }
    } else {
      setValid(null);
      setErrors(required ? [REGEX_FEEDBACK.required] : []);
    }
  }

  return {valid, errors, isValidating, validate};
}

export default useInputValidate;
