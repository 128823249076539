import React from 'react';
import PropTypes from 'prop-types';
import Profile from './Profile';

ProfileEnquiry.propTypes = {
  data: PropTypes.object.isRequired,
};

function ProfileEnquiry(props) {
  const {data, path, ...rest} = props;

  const {sender_company, sender_company_logo, sender_name} = data || {};

  return (
    <Profile
      image={sender_company_logo}
      title={sender_name}
      subtitle={sender_company}
      alt={sender_name}
      {...rest}
    />
  );
}

export default ProfileEnquiry;
