import React, {useState, useEffect} from 'react';
import Autosuggest from 'react-autosuggest';
import cx from 'classnames';
import useAlert from 'hooks/useAlert';
import {debounce} from 'throttle-debounce';
import {defaultTheme} from 'react-autosuggest/dist/theme';

import './InputSuggest.scss';

InputSuggest.defaultProps = {
  onSuggestionSelected: () => {},
};

function InputSuggest(props) {
  const {
    action,
    defaultValue,
    name,
    searchName,
    placeholder,
    required,
    ...rest
  } = props;

  const {alertByError} = useAlert();
  const [value, setValue] = useState(defaultValue || '');
  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    load(value);
  }, [value]);

  const load = async (value) => {
    const search = {
      [searchName]: value,
    };

    try {
      const response = await action(search);

      setSuggestions(response.data);
    } catch (e) {
      alertByError(e);
    }
  };

  const loadDebounced = (option) => debounce(500, load(option));

  const onSuggestionsClearRequested = () => setSuggestions([]);
  const onSuggestionsFetchRequested = (option) => loadDebounced(option.value);
  const renderSuggestion = (suggestion) => props.getOptionLabel(suggestion);
  const getSuggestionValue = (suggestion) => props.getOptionValue(suggestion);
  const onInputChange = (e, {newValue}) => {
    setValue(newValue);
  };
  const onSuggestionSelected = (e, value) => {
    return props.onSuggestionSelected(value.suggestion);
  };

  const inputProps = {
    name: name,
    value: value,
    required: required,
    onChange: onInputChange,
    placeholder: placeholder,
    className: cx('form-control form-control-search', {
      'form-control-sm': props.size === 'sm',
      'form-control-lg': props.size === 'lg',

      [props.className]: !!props.className,
    }),
    autoFocus: props.autoFocus,
    minLength: '3',
    maxLength: '200',
  };

  const containerProps = {
    className: cx({
      'react-suggest--valid': required && !!value,
      'react-suggest--invalid': required && !value,
    }),
  };

  return (
    <Autosuggest
      theme={{
        ...defaultTheme,
        inputFocused: {
          boxShadow: '0 0 10px rgba(51,0,114,.1)',
        },
        inputOpen: {
          outline: 'none',
          boxShadow: '0 0 10px rgba(51,0,114,.1)',
        },
      }}
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      focusInputOnSuggestionClick={false}
      highlightFirstSuggestion={false}
      containerProps={containerProps}
      inputProps={inputProps}
      onSuggestionSelected={onSuggestionSelected}
      {...rest}
    />
  );
}

export default InputSuggest;
