import React, {useState} from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
  Navbar,
  Container,
  Button,
  Collapse,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import {InternalLink, LinkLogo} from 'components/Links';
import {Icon} from 'components/Icons';
import PageHeaderSearch from './PageHeaderSearch';
import PageHeaderNavPrivate from './PageHeaderNavPrivate';
import PageHeaderNavPublic from './PageHeaderNavPublic';
import {useAuth} from 'contexts/AuthContext';
import {includes} from 'lodash';
import {
  APP_STAGE,
  OPEN_LANDING,
  SHOW_CB_SEARCH_LINK,
} from 'constants/environment';

const THEMES = {
  dark: 'dark',
  default: 'default',
  light: 'light',
  transparent: 'transparent',
};

PageHeader.propTypes = {
  logo: PropTypes.bool,
  theme: PropTypes.oneOf([
    'dark',
    'light',
    'default',
    'transparent',
    true,
    false,
  ]),
};

PageHeader.defaultProps = {
  logo: true,
  theme: 'default',
  fluid: true,
  bordered: true,
  fixed: false,
  search: true,
};

function PageHeader(props) {
  const {
    theme,
    search,
    fluid,
    bordered,
    // fixed,
    aside,
    navLinks,
    collapsed,
    setCollapseAside,
    isDesktop,
    isPublic,
  } = props;

  const [isNavOpen, setIsNavOpen] = useState(false);

  function toggle() {
    setIsNavOpen(!isNavOpen);
  }

  const {isAuth} = useAuth();

  const _theme = THEMES.hasOwnProperty(theme) ? THEMES[theme] : 'default';

  const _className = cx(
    'navbar-height navbar-flush navbar-fixed border-secondary border-bottom px-0',
    {
      [`navbar-${_theme}`]: _theme,
      'navbar-bordered': bordered,
      // 'navbar-fixed': fixed,
    }
  );

  const env = ['development', 'staging', 'alpha'];
  const isDev = includes(env, APP_STAGE);
  const isOpenLanding = OPEN_LANDING === '1' || OPEN_LANDING === 1;

  const showContent = isDev || isOpenLanding;
  const showCBSearchLink =
    SHOW_CB_SEARCH_LINK === '1' || SHOW_CB_SEARCH_LINK === 1;

  return (
    <>
      <Navbar
        tag="header"
        expand="lg"
        className={_className}
        style={{zIndex: 1005}}
      >
        <Container fluid={fluid}>
          <div className="navbar-nav-wrap">
            <div className="d-flex align-items-center">
              <div className="navbar-brand-wrapper mb-2 ml-3 ml-lg-0">
                <LinkLogo color={_theme === 'dark' ? 'light' : 'dark'} />
              </div>

              {/*Navbar Vertical Toggle*/}
              {aside && (
                <Button
                  color="white"
                  className={cx('close mr-3 d-xs-inline', {
                    'd-lg-none': !isAuth,
                    'd-sm-none': isAuth && isDesktop,
                  })}
                  onClick={setCollapseAside}
                >
                  <Icon
                    type="tio"
                    icon={collapsed ? 'first-page' : 'last-page'}
                  />
                </Button>
              )}
              {/*End Navbar Vertical Toggle*/}

              {/*Search Form*/}

              {search && (
                <div style={{maxWidth: '270px'}}>
                  <PageHeaderSearch />
                </div>
              )}

              {/*End Search Form*/}
            </div>

            {navLinks && (
              <div className="navbar-nav-wrap-content-right">
                {isAuth ? (
                  <PageHeaderNavPrivate
                    toggle={toggle}
                    theme={_theme}
                    isPublic={isPublic}
                    isDesktop={isDesktop}
                  />
                ) : (
                  <PageHeaderNavPublic toggle={toggle} />
                )}
              </div>
            )}
          </div>
        </Container>
        <Collapse
          className={cx('border position-absolute', {
            'd-lg-none': isPublic && !isAuth,
            'd-xl-none': isPublic,
          })}
          style={{zIndex: 1000, top: '100%', right: 0}}
          isOpen={isNavOpen}
        >
          <Card>
            <CardBody>
              {/* <Container></Container> */}
              <Nav vertical>
                <NavItem>
                  <NavLink href="https://www.ukas.com" rel="noreferrer">
                    UKAS.com
                  </NavLink>
                </NavItem>

                {showContent && (
                  <NavItem onClick={toggle}>
                    <InternalLink
                      path="CONTACT"
                      className="text-primary h-100 d-block nav-link"
                    >
                      Contact
                    </InternalLink>
                  </NavItem>
                )}

                {showCBSearchLink && (
                  <NavItem onClick={toggle}>
                    <InternalLink
                      path="CB_SEARCH"
                      className="text-primary h-100 d-block nav-link"
                    >
                      Participating Certification Bodies
                    </InternalLink>
                  </NavItem>
                )}
              </Nav>

              {!isAuth && showContent && (
                <div className="d-sm-none">
                  <hr />
                  <Nav vertical>
                    <InternalLink
                      path="SIGN_UP"
                      className="text-primary h-100 d-block nav-link"
                    >
                      Sign Up
                    </InternalLink>
                  </Nav>
                </div>
              )}
            </CardBody>
          </Card>
        </Collapse>
      </Navbar>
    </>
  );
}

export default PageHeader;
