import React from 'react';
import PropTypes from 'prop-types';

import {IMG_LOGOS} from 'constants/images';

const WIDTH = {
  sm: '1200px',
  md: '160px',
  lg: '240px',
};

ImageLogo.propTypes = {
  color: PropTypes.oneOf(['light', 'dark', 'grey', 'primary']).isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'lg']).isRequired,
};

ImageLogo.defaultProps = {
  color: 'light',
  size: 'md',
};

function ImageLogo(props) {
  const {color, size} = props;

  const src = IMG_LOGOS[color];

  return <img style={{width: WIDTH[size]}} src={src} alt="UKAS Logo" />;
}

export default ImageLogo;
