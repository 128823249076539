import React from 'react';
import Pagination from './Pagination';

function PaginationStateful(props) {
  const {meta, query, update, className} = props;
  const {total_pages} = meta || {};
  const {offset} = query;

  function handleChange({selected}) {
    const _query = {offset: selected > 0 ? selected : ''};
    update(_query);
  }

  return (
    <Pagination
      total={total_pages}
      offset={offset}
      handleChange={handleChange}
      className={className}
    />
  );
}

export default PaginationStateful;
