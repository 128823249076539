import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {Spinner} from 'reactstrap';

SpinnerLoading.propTypes = {
  isLoading: PropTypes.bool,
  position: PropTypes.oneOf(['fixed', 'absolute', 'relative']),
};

SpinnerLoading.defaultProps = {
  isLoading: true,
  position: 'absolute',
};

function SpinnerLoading(props) {
  const {isLoading, position} = props;

  if (!isLoading) return null;

  return (
    <Spinner
      style={{
        top: 'calc(50% - 1rem)',
        left: 'calc(50% - 1rem)',
        zIndex: '1',
      }}
      className={cx({
        [`position-${position}`]: position,
      })}
    />
  );
}

export default SpinnerLoading;
