import React, {useEffect} from 'react';
import {useLayout} from 'contexts/LayoutContext';

Layouts.propTypes = {};
Layouts.defaultProps = {
  header: true,
  search: true,
  footer: true,
  aside: false,
  asideOpen: false,
  fluid: true,
  navLinks: true,
};

export function Layouts(props) {
  const {
    header,
    search,
    footer,
    aside,
    asideOpen,
    fluid,
    navLinks,
    isPublic,
    children,
  } = props;
  const {setLayout} = useLayout();

  useEffect(() => {
    setLayout({
      header,
      search,
      footer,
      aside,
      asideOpen,
      fluid,
      navLinks,
      isPublic,
    });
  }, []);

  return children;
}

export const BaseLayout = (props) => <Layouts {...props} />;

export const PrivateLayout = (props) => {
  const {
    header = true,
    search = true,
    footer = true,
    aside = true,
    asideOpen = true,
    fluid = true,
    ...rest
  } = props;

  return (
    <Layouts
      header={header}
      search={search}
      footer={footer}
      aside={aside}
      asideOpen={asideOpen}
      fluid={fluid}
      {...rest}
    />
  );
};

export const PublicLayout = (props) => {
  const {
    header = true,
    search = true,
    footer = true,
    aside = false,
    fluid = false,
    isPublic = true,
    ...rest
  } = props;

  return (
    <Layouts
      header={header}
      search={search}
      footer={footer}
      aside={aside}
      fluid={fluid}
      isPublic={isPublic}
      {...rest}
    />
  );
};

export default BaseLayout;
