import React, {useState} from 'react';
import cx from 'classnames';

import {REGEX_PATTERN} from 'constants/regex';
import {Input, InputGroup, InputGroupAddon, InputGroupText} from 'reactstrap';
import {Icon} from 'components/Icons';

InputPassword.defaultProps = {
  name: 'password',
  type: 'password',
  autoComplete: 'off',
  pattern: REGEX_PATTERN.password,
  confirm: true,
};

function InputPassword(props) {
  const {type, children, className, onChange, confirm, ...rest} = props;
  const [show, setShow] = useState(false);

  function handleChange(e) {
    const target = e.target;
    const inputGroup = target.closest('.input-group');

    if (confirm) {
      if (target.checkValidity()) {
        inputGroup.classList.remove('is-invalid');
      } else {
        inputGroup.classList.add('is-invalid');
      }
    }

    if (onChange) onChange(e);
  }

  function handleShow(e) {
    e.preventDefault();
    setShow(!show);
  }

  return (
    <InputGroup className={cx('input-group-merge', className)}>
      <Input type={show ? 'text' : type} onKeyUp={handleChange} {...rest} />

      <InputGroupAddon addonType="append">
        <InputGroupText className="btn" role="button" onClick={handleShow}>
          <Icon type="fa" icon={show ? 'eye' : 'eye-slash'} />
        </InputGroupText>
      </InputGroupAddon>

      {children}
    </InputGroup>
  );
}

export default InputPassword;
