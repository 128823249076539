import {InternalLink, LinkLogo} from 'components/Links';
import {useAuth} from 'contexts/AuthContext';
import React from 'react';
import {Button, Navbar} from 'reactstrap';
import cx from 'classnames';
import {AsideNavItem, AsideSubTitle} from './PageSidebarPrivate';
import {Icon} from 'components/Icons';

function PageSidebarPublic(props) {
  const {asideOpen, isDesktop, collapsed, setCollapseAside} = props;
  const {isAuth} = useAuth();

  let navigation = [
    {
      path: 'DASHBOARD',
      tag: InternalLink,
      icon: 'sliders-h',
      text: 'Dashboard',
    },
    {
      link: 'https://www.ukas.com',
      text: 'UKAS.com',
    },
    {
      path: 'CONTACT',
      tag: InternalLink,
      text: 'Contact',
    },
    {
      path: 'CB_SEARCH',
      tag: InternalLink,
      text: 'Participating Certification Bodies',
    },
  ].filter((v) => v);

  const navAccount = [
    {
      path: 'LOG_IN',
      tag: InternalLink,
      text: 'Login',
    },
    {
      path: 'SIGN_UP',
      tag: InternalLink,
      text: 'Sign up',
    },
  ];

  navigation = isAuth ? navigation.slice(0, 1) : navigation.splice(1, 3);

  return (
    <Navbar
      tag="aside"
      style={{
        minWidth: collapsed && '17rem',
      }}
      className={cx(
        'navbar navbar-vertical-aside navbar-vertical navbar-vertical-fixed navbar-expand-xl navbar-bordered navbar-vertical-aside-initialized',
        {
          'd-lg-none': !asideOpen,
          'navbar-dark': isAuth,
          'navbar-light': !isAuth,
        }
      )}
    >
      <div className="navbar-vertical-container">
        <div className="d-flex align-items-center">
          <div className="navbar-brand-wrapper justify-content-between mb-2">
            <LinkLogo color={isAuth ? 'light' : 'dark'} />
          </div>

          <Button
            color="white"
            className={cx('close mr-4 d-xs-inline', {
              'd-lg-none': !isAuth,
              'd-sm-none': isAuth && isDesktop,
            })}
            onClick={setCollapseAside}
          >
            <Icon
              type="tio"
              icon={collapsed ? 'first-page' : 'last-page'}
              color="white"
            />
          </Button>
        </div>
        <div className="navbar-vertical-content">
          <ul className="navbar-nav navbar-nav-lg nav-tabs">
            <>
              <AsideSubTitle title="Navigation" className="text-dark" />

              {navigation.map((item, index) => (
                <AsideNavItem key={index} {...item} tag={InternalLink} />
              ))}

              <li className="nav-item">
                <div className="nav-divider" />
              </li>

              {!isAuth &&
                navAccount.map((item, index) => (
                  <AsideNavItem key={index} {...item} tag={InternalLink} />
                ))}
            </>
          </ul>
        </div>
      </div>
    </Navbar>
  );
}

export default PageSidebarPublic;
