import React, {useEffect, useState, useRef, useCallback} from 'react';
import {isEmpty, debounce} from 'lodash';
import {FormFeedback, Input, InputGroup} from 'reactstrap';
import {TextLoading} from 'components/Loadings';
import PropTypes from 'prop-types';
import useCheckExists from './useInputValidate';

InputCheckExists.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  action: PropTypes.func.isRequired,
  setIsLoading: PropTypes.func,
};

InputCheckExists.defaultProps = {
  type: 'text',
  required: false,
};

function InputCheckExists(props) {
  const {type, name, action, required, setIsLoading, ...rest} = props;
  const inputRef = useRef();
  const [inputValue, setInputValue] = useState('');

  const {valid, errors, isValidating, validate} = useCheckExists(
    action,
    name,
    required
  );

  useEffect(() => {
    const current = inputRef.current;
    if (current) {
      const closest = current.closest('.input-group');

      if (valid) {
        closest.classList.remove('is-invalid');
        current.setCustomValidity('');
      } else if (valid === false) {
        closest.classList.add('is-invalid');
        current.setCustomValidity(' ');
      } else if (valid === null && !required) {
        closest.classList.remove('is-invalid');
        current.setCustomValidity('');
      }
    }
  }, [inputValue, valid]);

  useEffect(() => {
    if (setIsLoading) setIsLoading(isValidating);
  }, [isValidating]);

  const searchFn = useCallback(debounce(validate, 250), []);

  return (
    <React.Fragment>
      <InputGroup>
        <Input
          type={type}
          name={name}
          innerRef={inputRef}
          onKeyUp={(e) => {
            const {value} = e.target;

            setInputValue(value);
            searchFn(value);
          }}
          required={required}
          {...rest}
        />
        {inputValue === '' && valid === null && (
          <FormFeedback>
            {!isEmpty(errors) && errors.map((e) => e).join('. ')}
          </FormFeedback>
        )}
      </InputGroup>
      {!isValidating && inputValue !== '' && valid !== null && (
        <FormFeedback>
          {!isEmpty(errors) && errors.map((e) => e).join('. ')}
        </FormFeedback>
      )}

      {isValidating && <TextLoading isLoading />}
    </React.Fragment>
  );
}

export default InputCheckExists;
