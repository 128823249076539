import styles from './PageContainer.module.scss';
import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

PageContainer.propTypes = {
  aside: PropTypes.bool,
};

PageContainer.defaultProps = {
  aside: false,
};

function PageContainer(props) {
  const {aside, collapsed, asideOpen, isDesktop, children} = props;

  const openSidebar =
    (asideOpen && isDesktop) || // private
    (!asideOpen && !isDesktop && collapsed); // public

  const closeSidebar =
    ((asideOpen && !collapsed) || (!asideOpen && !collapsed)) &&
    !collapsed &&
    !isDesktop;

  const _className = cx(styles['page-container'], {
    'has-navbar-vertical-aside': !!aside && (collapsed || asideOpen), //show sidebar init. if collapsed or in private layout
    'navbar-vertical-aside-show-xl': openSidebar,
    'navbar-vertical-aside-closed-mode': closeSidebar,
  });

  return <div className={_className}>{children}</div>;
}

export default PageContainer;
