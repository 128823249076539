import React, {useState, useEffect} from 'react';
import Limit from './Limit';

function LimitStateful(props) {
  const {meta, update, initialLimit} = props;
  const [value, setValue] = useState(initialLimit);
  const {total_rows, order_by} = meta;

  useEffect(() => {
    setValue(initialLimit);
  }, [order_by]);

  function handleChange(e) {
    const value = e.target.value;
    setValue(value);
    update({limit: value});
  }

  let filters = [];
  if (initialLimit < 10) filters.push(initialLimit);
  if (total_rows >= 10) filters.push(10);
  if (total_rows >= 20) filters.push(20);
  if (total_rows >= 50) filters.push(50);
  if (total_rows >= 100) filters.push(100);
  filters = filters.map((f) => parseInt(f));
  filters = [...new Set(filters)];
  filters = filters.sort((a, b) => a - b);
  return (
    <Limit
      total={total_rows}
      filters={filters}
      value={value}
      handleChange={handleChange}
    />
  );
}

export default LimitStateful;
