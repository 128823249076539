import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import ScaleLoader from 'react-spinners/ScaleLoader';

PageLoading.propTypes = {
  isLoading: PropTypes.bool,
  position: PropTypes.oneOf(['fixed', 'absolute', 'relative']),
};

PageLoading.defaultProps = {
  isLoading: true,
  position: 'fixed',
};

function PageLoading(props) {
  const {isLoading, position} = props;

  if (!isLoading) return null;

  return (
    <div
      className={cx({
        [`position-${position}`]: position,
      })}
      style={{zIndex: 1, top: 0, left: 0, width: '100vw', height: '100vh'}}
    >
      <div
        className={cx({
          [`position-${position}`]: position,
        })}
        style={{top: '50%', left: 'calc(50% - 40px)'}}
      >
        <ScaleLoader
          loading
          height={35}
          width={4}
          radius={2}
          margin={2}
          color={'var(--primary)'}
        />
      </div>
    </div>
  );
}

export default PageLoading;
