import React from 'react';
import PropTypes from 'prop-types';

import {IMG_LOGOS_CROWN} from 'constants/images';

ImageLogo.propTypes = {
  color: PropTypes.oneOf(['light', 'dark', 'grey']).isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'lg']).isRequired,
};

ImageLogo.defaultProps = {
  color: 'light',
  size: 'md',
};

function ImageLogo(props) {
  const {color} = props;

  const src = IMG_LOGOS_CROWN[color];

  return <img src={src} alt="UKAS Logo" />;
}

export default ImageLogo;
