import React from 'react';
import {NavLink} from 'react-router-dom';
import {get} from 'lodash/object';
import {isEmpty} from 'lodash/lang';

import {ROUTE_PATH} from 'constants/routes';
import {COMPANY_TYPE} from 'constants/types';
import {useAuth} from 'contexts/AuthContext';

import {Button, Navbar, NavItem, NavLink as BootNavLink} from 'reactstrap';
import {LinkLogo} from 'components/Links';
import {Icon} from 'components/Icons';
import cx from 'classnames';

PageSidebarPrivate.propTypes = {};

export const AsideSubTitle = ({title, className}) => (
  <li className={cx('nav-item', className)}>
    <small className="nav-subtitle text-muted" title={title}>
      {title}
    </small>
  </li>
);

export const AsideNavItem = ({text, link, tag, icon, ...rest}) => {
  if (link) {
    return (
      <NavItem>
        <a href={link} rel="noopener noreferrer" {...rest} className="nav-link">
          <span className="text-truncate">{text}</span>
        </a>
      </NavItem>
    );
  }

  return (
    <NavItem>
      <BootNavLink tag={tag || NavLink} {...rest}>
        {icon && <Icon type="fa" icon={icon} className="mr-2" />}
        <span className="text-truncate">{text}</span>
      </BootNavLink>
    </NavItem>
  );
};

// @todo
// Routing
// Permissions

function hasPermit(pathPermit) {
  const {permission} = useAuth();
  return get(permission, pathPermit);
}

function PageSidebarPrivate(props) {
  const {isDesktop, collapsed, setCollapseAside} = props;
  const {companyType, isAuth} = useAuth();

  const isAB = companyType === COMPANY_TYPE.ab;
  const isCB = companyType === COMPANY_TYPE.cb;
  const isCompany = companyType === COMPANY_TYPE.company;

  const navigation = [
    {
      exact: true,
      to: ROUTE_PATH.DASHBOARD.OVERVIEW,
      icon: 'sliders-h',
      text: 'Dashboard',
    },

    isCompany && {
      to: ROUTE_PATH.DASHBOARD.WATCHLIST.INDEX,
      icon: 'star',
      text: 'Watchlist',
    },
    {
      to: ROUTE_PATH.DASHBOARD.SEARCH_HISTORY.INDEX,
      icon: 'search',
      text: 'Search History',
    },
    hasPermit('market_trends.access') && {
      to: ROUTE_PATH.DASHBOARD.MARKET_TRENDS.OVERVIEW,
      icon: 'chart-pie',
      text: 'Market Trends',
    },
  ].filter((v) => v);

  const management = [
    isAB &&
      hasPermit('certification_bodies.access') && {
        to: ROUTE_PATH.DASHBOARD.CBS.LIST,
        icon: 'landmark',
        text: 'Certification Bodies',
      },
    (isAB || isCB) &&
      hasPermit('certified_entities.access') && {
        to: ROUTE_PATH.DASHBOARD.CES.LIST,
        icon: 'building',
        text: 'Certified Entities',
      },
    (isAB || isCB) &&
      hasPermit('certifications.access') && {
        to: ROUTE_PATH.DASHBOARD.CERTS.LIST,
        icon: 'certificate',
        text: 'Certifications',
      },
    isAB &&
      hasPermit('libraries.access') && {
        to: ROUTE_PATH.DASHBOARD.LIBRARIES.INDEX,
        icon: 'book',
        text: 'Libraries',
      },
    isCB &&
      hasPermit('scopes.access') && {
        to: '/scopes',
        icon: 'check-double',
        text: 'Scopes',
      },
    (isAB || isCB) &&
      hasPermit('data_management.access') && {
        to: '/data-management',
        icon: 'file-upload',
        text: 'Data Management',
      },
    isCB &&
      hasPermit('branches.access') && {
        to: ROUTE_PATH.DASHBOARD.BRANCHES.LIST,
        icon: 'code-branch',
        text: 'Branches',
      },
  ].filter((v) => v);

  const messaging = [
    hasPermit('enquiries.access') && {
      to: ROUTE_PATH.DASHBOARD.ENQUIRIES.INDEX,
      icon: 'envelope',
      text: 'Enquiries',
    },
  ].filter((v) => v);

  const settings = [
    (isAB || isCB) &&
      hasPermit('users_permissions.access') && {
        to: ROUTE_PATH.DASHBOARD.USERS.LIST,
        icon: 'users-cog',
        text: 'Users and Permissions',
      },
    isCompany && {
      to: ROUTE_PATH.DASHBOARD.COMPANY_PROFILE.INDEX,
      icon: 'user-circle',
      text: 'Company Profile',
    },
    isCB &&
      hasPermit('profile.access') && {
        to: ROUTE_PATH.DASHBOARD.CB_PROFILE.VIEW,
        icon: 'user',
        text: 'Certification Body Profile',
      },
    // undefined and false are different
    hasPermit('settings.access') !== false && {
      to: ROUTE_PATH.DASHBOARD.SETTINGS.USER,
      icon: 'cog',
      text: 'Settings',
    },
  ].filter((v) => v);

  const support = [
    //hasPermit('support.access') &&
    isCB && {
      to: ROUTE_PATH.DASHBOARD.SUPPORT,
      icon: 'question-circle',
      text: 'User Guide',
    },
  ].filter((v) => v);

  const assets = [
    //hasPermit('support.access') &&
    isCB && {
      to: ROUTE_PATH.DASHBOARD.ASSET_PACKS,
      icon: 'images',
      text: 'asset pack',
    },
  ].filter((v) => v);

  const showSupport = !isEmpty(support) || !isEmpty(assets);

  return (
    <Navbar
      tag="aside"
      className="navbar navbar-vertical-aside navbar-vertical navbar-vertical-fixed navbar-expand-xl navbar-bordered navbar-vertical-aside-initialized navbar-dark"
    >
      <div className="navbar-vertical-container">
        <div className="d-flex align-items-center">
          <div className="navbar-brand-wrapper justify-content-between mb-2">
            <LinkLogo color={isAuth ? 'light' : 'dark'} />
          </div>

          <Button
            color="white"
            className={cx('close mr-4 d-xs-inline', {
              'd-lg-none': !isAuth,
              'd-sm-none': isAuth && isDesktop,
            })}
            onClick={setCollapseAside}
            outline
          >
            <Icon
              type="tio"
              icon={collapsed ? 'first-page' : 'last-page'}
              color="white"
            />
          </Button>
        </div>

        <div
          className="navbar-vertical-content"
          style={{paddingBottom: '130px'}}
        >
          <ul className="navbar-nav navbar-nav-lg nav-tabs">
            {!isEmpty(navigation) && (
              <>
                <AsideSubTitle title="Navigation" />

                {navigation.map((item, index) => (
                  <AsideNavItem key={index} {...item} />
                ))}

                <li className="nav-item">
                  <div className="nav-divider" />
                </li>
              </>
            )}

            {!isEmpty(management) && (
              <>
                <AsideSubTitle title="Management" />

                {management.map((item, index) => (
                  <AsideNavItem key={index} {...item} />
                ))}

                <li className="nav-item">
                  <div className="nav-divider" />
                </li>
              </>
            )}

            {!isEmpty(messaging) && (
              <>
                <AsideSubTitle title="Messaging" />

                {messaging.map((item, index) => (
                  <AsideNavItem key={index} {...item} />
                ))}

                <li className="nav-item">
                  <div className="nav-divider" />
                </li>
              </>
            )}

            {!isEmpty(settings) && (
              <>
                <AsideSubTitle title="Settings" />

                {settings.map((item, index) => (
                  <AsideNavItem key={index} {...item} />
                ))}
              </>
            )}

            {showSupport && (
              <>
                <li className="nav-item">
                  <div className="nav-divider" />
                </li>

                <AsideSubTitle title="Help and Support" />

                {!isEmpty(support) &&
                  support.map((item, index) => (
                    <AsideNavItem key={index} {...item} />
                  ))}

                {!isEmpty(assets) &&
                  assets.map((item, index) => (
                    <AsideNavItem key={index} {...item} />
                  ))}
              </>
            )}
          </ul>
        </div>
      </div>
    </Navbar>
  );
}

export default PageSidebarPrivate;
