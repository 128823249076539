import React, {useRef, useState, useEffect} from 'react';
import {InputGroup, Input, FormFeedback} from 'reactstrap';
import {REGEX_FEEDBACK} from 'constants/regex';

function InputLimit(props) {
  const {type, name, limit, limitMessage, required, ...rest} = props;
  const inputRef = useRef();
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const current = inputRef.current;
    if (current) {
      const closest = current.closest('.input-group');

      if (limit < inputValue.length) {
        closest.classList.add('is-invalid');
        current.setCustomValidity(' ');
      } else {
        closest.classList.remove('is-invalid');
        current.setCustomValidity('');
      }
    }
  }, [inputValue]);

  return (
    <React.Fragment>
      <InputGroup>
        <Input
          innerRef={inputRef}
          onKeyUp={(e) => {
            const {value} = e.target;

            setInputValue(value || '');
          }}
          type={type}
          name={name}
          required={required}
          {...rest}
        />
        {inputValue.length === 0 && required && (
          <FormFeedback>{REGEX_FEEDBACK.required}</FormFeedback>
        )}
      </InputGroup>
      {limit < inputValue.length && <FormFeedback>{limitMessage}</FormFeedback>}
    </React.Fragment>
  );
}

export default InputLimit;
