import React, {useState} from 'react';
import {useAuth} from 'contexts/AuthContext';
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from 'reactstrap';
import {InternalLink} from 'components/Links';
import {Avatar} from 'components/Avatars';
import {Icon} from 'components/Icons';
import {ProfileUser} from 'components/Profiles';
import {useNotification} from 'contexts/NotificationContext';

function PageHeaderNavPrivateUser() {
  const {account} = useAuth();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const {account_image, account_first_name, account_last_name} = account;

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const {count} = useNotification();
  const {enquiry, update} = count || {};
  let hasNotif, hasEnquiry;

  if (count) {
    hasNotif = update > 0;
    hasEnquiry = enquiry > 0;
  }

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle className="navbar-dropdown-account-wrapper border-0">
        <Avatar
          image={account_image}
          alt={[account_first_name, account_last_name].join(' ')}
        >
          {(hasEnquiry || hasNotif) && (
            <span className="btn-status btn-xs-status btn-status-danger d-sm-none" />
          )}
        </Avatar>
      </DropdownToggle>

      <DropdownMenu style={{width: '16rem'}} right>
        <div className="mx-3">
          <ProfileUser data={account} path="" />
        </div>
        <DropdownItem divider />

        <div className="d-sm-none">
          <DropdownItem header>Messaging</DropdownItem>
          <DropdownItem tag={InternalLink} path="DASHBOARD.ENQUIRIES.OPEN">
            <Icon type="fa" icon="envelope" append="Enquiries" />
            {hasEnquiry && (
              <span className="btn-status btn-xs-status btn-status-danger position-relative" />
            )}
          </DropdownItem>
          <DropdownItem
            tag={InternalLink}
            path="DASHBOARD.SETTINGS.NOTIFICATIONS"
          >
            <Icon type="fa" icon="bell" append="Notifications" />
            {hasNotif && (
              <span className="btn-status btn-xs-status btn-status-danger  position-relative" />
            )}
          </DropdownItem>
          <DropdownItem divider />
        </div>
        <DropdownItem header>Settings</DropdownItem>
        <DropdownItem tag={InternalLink} path="DASHBOARD.SETTINGS.USER">
          <Icon type="fa" icon="users-cog" append="User" />
        </DropdownItem>
        <DropdownItem
          tag={InternalLink}
          path="DASHBOARD.SETTINGS.SECURITY.OVERVIEW"
        >
          <Icon type="fa" icon="cog" append="Security" />
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem tag={InternalLink} path="LOG_OUT">
          <Icon type="fa" fixedWidth append="Log out" icon="sign-out-alt" />
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}

export default PageHeaderNavPrivateUser;
